import Vuex, { StoreOptions } from "vuex";
import VuexPersistance from "vuex-persist";

import { auth } from "./auth";
import { reimbursements } from "./reimbursements";
import { talents } from "./talents";
import { RootState } from "./types";
import { user } from "./user";
import { profile } from "./profile";

//import createPersistedState from "vuex-persistedstate";
const vuexLocal = new VuexPersistance<RootState>({
  storage: window.localStorage,
});

const store: StoreOptions<RootState> = {
  state: {},
  modules: {
    user,
    auth,
    reimbursements,
    talents,
    profile,
  },
  mutations: {},
  actions: {},
  //plugins: [createPersistedState()],
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
