
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import ErrorState from "@/components/ErrorState.vue";
import TextField from "@/components/textField/index.vue";
import {
  getCompleteAccountUser,
  completeAccount,
} from "@/components/api/users.api";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";

export default defineComponent({
  name: "Complete Account",
  props: {
    token: String,
  },
  components: {
    ErrorState,
    TextField,
  },
  setup(props) {
    //Validation
    const schema = yup.object({
      first_name: yup.string().required().max(50).min(3).label("First name"),
      last_name: yup.string().required().max(50).min(3).label("Last name"),
      email: yup.string().required().email(),
      password: yup
        .string()
        .required()
        .matches(
          /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
          "Must contain at least one uppercase, special character and number"
        ),
    });
    const { meta, handleSubmit, errors } = useForm({
      validationSchema: schema,
    });
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      const user = {
        user: {
          ...values,
          password_confirmation: values.password,
        },
      };
      completeUserAccount(user);
    });

    //Variables
    const loading = ref(false);
    const error = ref(false);
    const step = ref(1);
    const router = useRouter();
    const user_id = ref("");

    //Functions
    async function getUser(token: string) {
      const data = await getCompleteAccountUser(token);
      if (data) {
        const user = data.data;
        user_id.value = user.id;
        email.value = user.email;
      }
    }

    async function completeUserAccount(user: any) {
      const data = await completeAccount(user_id.value, user);
      if (data) {
        router.push("/login");
      } else {
        error.value = true;
      }
    }

    //Hooks
    onMounted(() => {
      if (props.token) {
        getUser(props.token);
      } else {
        error.value = true;
      }
    });

    return {
      loading,
      error,
      step,
      first_name,
      last_name,
      email,
      password,
      onSubmit,
      schema,
      errors,
    };
  },
});
