
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import UserCard from "@/components/UserCard.vue";
import Spinner from "@/components/Spinner.vue";
import ErrorState from "@/components/ErrorState.vue";
import Files from "@/components/Talent/Files.vue";
//import Job from "@/components/Talent/Job.vue";
import Position from "@/components/Talent/Position.vue";
import Onboarding from "@/components/Talent/Onboarding.vue";
import Timeline from "@/components/Timeline.vue";
import EmergencyContacts from "@/components/Talent/EmergencyContacts.vue";
import PaymentBreakdown from "@/components/Talent/PaymentBreakdown.vue";
import PaymentInformation from "@/components/Talent/PaymentInformation.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { getTalent } from "@/components/api/talent.api";
import { Talent } from "@/types/talent.interface";

export default defineComponent({
  name: "Talent",
  components: {
    Navbar,
    UserCard,
    Spinner,
    ErrorState,
    DateFormatter,
    Files,
    //Job,
    Position,
    PaymentBreakdown,
    PaymentInformation,
    Timeline,
    EmergencyContacts,
    Onboarding,
  },
  data: () => ({
    user: {} as Talent,
    loading: false,
    error: false,
    previous_id: "",
    next_id: "",
    menu_onboarding: [
      {
        name: "timeline",
        selected: false,
      },
      {
        name: "job",
        selected: true,
      },
      {
        name: "files",
        selected: false,
      },
      {
        name: "emergency-contacts",
        selected: false,
      },
    ],
    menu: [
      {
        name: "timeline",
        selected: false,
      },
      {
        name: "role",
        selected: false,
      },
      {
        name: "payment-breakdown",
        selected: true,
      },
      {
        name: "payment-information",
        selected: false,
      },
      {
        name: "files",
        selected: false,
      },
      {
        name: "emergency-contacts",
        selected: false,
      },
    ],
    selectedPage: "payment-breakdown",
  }),
  watch: {
    $route(to, from) {
      const id = to.params.id as string;
      if (id) {
        this.loadData(id);
      }
    },
  },
  methods: {
    async loadData(id: string) {
      this.loading = true;
      this.refreshPage();
      const data = await getTalent(id);
      if (data) {
        this.user = data;
        this.setPreviousAndNext(id);
      } else {
        this.error = true;
      }
      this.loading = false;
    },
    changePage(index: number) {
      if (this.user.talent.nomilinea_profile) {
        this.selectedPage = this.menu[index].name;
      } else {
        this.selectedPage = this.menu_onboarding[index].name;
      }
      this.$router.push(
        `/talent/${this.$route.params.id}/${this.selectedPage}`
      );
    },
    refreshPage() {
      const link = this.$route.params.subpage;
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].name === link) {
          this.selectedPage = link;
          this.menu[i].selected = true;
        } else {
          this.menu[i].selected = false;
        }
      }
      for (let i = 0; i < this.menu_onboarding.length; i++) {
        if (this.menu_onboarding[i].name === link) {
          this.selectedPage = link;
          this.menu_onboarding[i].selected = true;
        } else {
          this.menu_onboarding[i].selected = false;
        }
      }
    },
    setPreviousAndNext(id: string) {
      this.previous_id = "";
      this.next_id = "";
      const ids = this.$store.getters.getIds;
      const pos = ids.indexOf(id);
      if (ids[pos - 1]) {
        this.previous_id = ids[pos - 1] as string;
      }
      if (ids[pos + 1]) {
        this.next_id = ids[pos + 1] as string;
      }
    },
  },
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
    navMenu() {
      if (this.user.talent.nomilinea_profile) {
        return this.menu;
      } else {
        return this.menu_onboarding;
      }
    },
  },
  mounted() {
    const id = this.$route.params.id as string;
    this.loadData(id);
  },
  updated() {
    this.refreshPage();
  },
});
