import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "inline" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "flex justify-center items-center h-12 w-12 bg-primary text-white rounded-full uppercase"
}
const _hoisted_5 = { class: "flex flex-col text-left ml-4" }
const _hoisted_6 = { class: "font-semibold truncate text-left" }
const _hoisted_7 = {
  key: 1,
  class: "font-semibold text-ellipsis overflow-hidden h-6"
}
const _hoisted_8 = { class: "text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.image,
            class: "h-12 w-12 rounded-full"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.initials), 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.id)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/talent/${_ctx.id}/job`)))
          }, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.convertString(_ctx.firstName)) + " " + _toDisplayString(_ctx.convertString(_ctx.lastName)), 1)
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.convertString(_ctx.firstName)) + " " + _toDisplayString(_ctx.convertString(_ctx.lastName)), 1)),
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.position), 1)
    ])
  ]))
}