import { client } from "./baseclient";

export async function getOnboardingList(user_id: string) {
  try {
    const { data } = await client.get(`/users/${user_id}/tasks`);
    return data;
  } catch (err) {
    return null;
  }
}
