import { client } from "./baseclient";
import store from "@/store";

export async function getDashboard() {
  const company_id = store.getters.getCompanyId;
  try {
    const { data } = await client.get(`/companies/${company_id}/dashboard`);
    return data;
  } catch (err) {
    return null;
  }
}
