
import { defineComponent, PropType, ref } from "vue";
import UserCard from "@/components/UserCard.vue";
import ModalReason from "@/components/Reimbursements/ModalReason.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { Reimbursement } from "@/types/reimbursement.interface";
import FileSaver from "file-saver";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "Approved Reimbursement",
  components: {
    UserCard,
    ModalReason,
    DateFormatter,
  },
  props: {
    reimbursement: Object as PropType<Reimbursement>,
  },
  setup(props) {
    const hover = ref(false);
    const openReason = ref(false);
    const openModal = ref(false);
    const showTooltip = ref(false);
    const openDropdown = ref("");
    const dropdown = ref(null);

    function downloadVoucher() {
      if (props.reimbursement?.vouchers) {
        for (let i = 0; i < props.reimbursement.vouchers.length; i++) {
          FileSaver.saveAs(props.reimbursement.vouchers[i].url);
        }
      }
      closeDropdown();
    }

    function showDropdown(id: string) {
      openDropdown.value = id;
    }
    onClickOutside(dropdown, (event) => {
      openDropdown.value = "";
    });

    function closeDropdown() {
      openDropdown.value = "";
    }

    return {
      openModal,
      showDropdown,
      dropdown,
      openDropdown,
      downloadVoucher,
      openReason,
      hover,
      showTooltip,
      closeDropdown,
    };
  },
});
