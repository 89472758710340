
import { defineComponent } from "vue";
import { addEmergencyContact } from "@/components/api/talent.api";
import TextField from "@/components/textField/index.vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";

export default defineComponent({
  name: "Modal Emergency Contacts",
  components: {
    TextField,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    talent_id: {
      type: String,
      default: "",
    },
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    let loading = false;
    let error = false;

    interface NewContact {
      first_name: string;
      last_name: string;
      phone: string;
      relationship: string;
      address: string;
    }

    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
      phone: yup.string().required().label("Phone"),
      relationship: yup.string().required().label("Relationship"),
      address: yup.string().label("Address"),
    });

    const { meta, handleSubmit, errors } = useForm({
      validationSchema: schema,
    });

    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: phone } = useField("phone");
    const { value: relationship } = useField("relationship");
    const { value: address } = useField("address");

    function closeModal() {
      emit("update:openModal", false);
    }

    async function createContact(values: NewContact) {
      loading = true;
      const data = await addEmergencyContact(
        props.talent_id,
        values.first_name,
        values.last_name,
        values.phone,
        values.relationship,
        values.address
      );
      if (data) {
        closeModal();
      } else {
        error = true;
      }
      loading = false;
    }

    const onSubmit = handleSubmit((values) => {
      let body = {} as NewContact;
      body.first_name = values.first_name as string;
      body.last_name = values.last_name as string;
      body.phone = values.phone as string;
      body.relationship = values.relationship as string;
      body.address = values.address as string;
      createContact(body);
    });

    return {
      first_name,
      last_name,
      phone,
      relationship,
      address,
      meta,
      errors,
      loading,
      error,
      onSubmit,
      closeModal,
    };
  },
});
