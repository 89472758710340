import { ActionTree } from "vuex";
import { RootState } from "../types";
import { AuthState } from "./types";
import router from "@/router";
import { recoverPassword, setPassword } from "@/components/api/users.api";

export const actions: ActionTree<AuthState, RootState> = {
  async changePassword({ commit }, email) {
    try {
      const token_id = await recoverPassword(email);
      if (token_id) {
        commit("SET_CHANGEPASSWORDTOKEN", token_id);
        commit("SET_CHANGEPASSWORDEMAIL", email);
      }
    } catch (err) {
      err;
    }
  },

  async setNewPassword({ commit, state }, data) {
    try {
      const token_id = state.change_password_token;
      const email = state.change_password_email;
      const token = await setPassword(
        token_id,
        data.password,
        data.password_confirmation
      );
      if (token) {
        commit("SET_USEREMAIL", email, { root: true });
        commit("SET_TOKEN", token);
        commit("SET_LOGIN", true);
        localStorage.setItem("token", token);
        router.push("/");
      }
      commit("SET_CHANGEPASSWORDTOKEN", "");
      commit("SET_CHANGEPASSWORDEMAIL", "");
    } catch (err) {
      err;
    }
  },
};
