
import { defineComponent } from "vue";
import Spinner from "@/components/Spinner.vue";
import { confirmEmail } from "@/components/api/users.api";

export default defineComponent({
  name: "Confirm email",
  components: {
    Spinner,
  },
  props: {
    token: String,
  },
  data: () => ({
    loading: false,
    error: false,
  }),
  methods: {
    async confirmEmail() {
      if (this.token) {
        const data = await confirmEmail(this.token);
        if (data) {
          this.loading = false;
        } else {
          this.error = true;
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.token) {
      await this.confirmEmail();
    } else {
      this.error = true;
    }
    this.loading = false;
  },
});
