
import { defineComponent, PropType } from "vue";
import UserCard from "@/components/UserCard.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { TalentDisplay } from "@/types/talentDisplay.interface";

export default defineComponent({
  name: "Active Talent",
  components: {
    UserCard,
    DateFormatter,
  },
  props: {
    talent: Object as PropType<TalentDisplay>,
    exchange_rate: Number,
  },
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
});
