import { MutationTree } from "vuex";

import { UserState } from "./types";

export enum UserMutations {
  SET_USEREMAIL = "SET_USEREMAIL",
  SET_USERID = "SET_USERID",
  SET_USERFIRSTNAME = "SET_USERFIRSTNAME",
  SET_USERLASTNAME = "SET_USERLASTNAME",
  SET_USERROLE = "SET_USERROLE",
  SET_COMPANYID = "SET_COMPANYID",
  SET_COMPANYNAME = "SET_COMPANYNAME",
  SET_SHOWSUGGESTEDACTIONS = "SET_SHOWSUGGESTEDACTIONS",
  SET_CURRENCY = "SET_CURRENCY",
  SET_COMPANY_PRODUCTS = "SET_COMPANY_PRODUCTS",
  SET_PAYMENTS = "SET_PAYMENTS",
}

export const mutations: MutationTree<UserState> = {
  [UserMutations.SET_USEREMAIL](state, payload: string) {
    state.email = payload;
  },
  [UserMutations.SET_USERID](state, payload: string) {
    state.user_id = payload;
  },
  [UserMutations.SET_USERFIRSTNAME](state, payload: string) {
    state.first_name = payload;
  },
  [UserMutations.SET_USERLASTNAME](state, payload: string) {
    state.last_name = payload;
  },
  [UserMutations.SET_USERROLE](state, payload: string) {
    state.role = payload;
  },
  [UserMutations.SET_COMPANYID](state, payload: string) {
    state.company_id = payload;
  },
  [UserMutations.SET_COMPANYNAME](state, payload: string) {
    state.company_name = payload;
  },
  [UserMutations.SET_SHOWSUGGESTEDACTIONS](state, payload: boolean) {
    state.show_suggested_actions = payload;
  },
  [UserMutations.SET_CURRENCY](state, payload: string) {
    state.currency = payload;
  },
  [UserMutations.SET_COMPANY_PRODUCTS](state, payload: []) {
    state.company_products = payload;
  },
  [UserMutations.SET_PAYMENTS](state, payload: Array<string>) {
    state.payments = payload;
  },
};
