
import { defineComponent, PropType } from "vue";
import { Nomilinea } from "@/types/nomilinea.interface";
import {
  modifyTalentInformation,
  getNomilineaOptions,
} from "@/components/api/talent.api";

export default defineComponent({
  name: "Payment Information",
  props: {
    id: String,
    department: String,
    job_title: String,
    entry_day: String,
    nomilinea: Object as PropType<Nomilinea>,
  },
  setup(props) {
    const info = {
      id: props.id || "",
      department: props.department || "",
      job_title: props.job_title || "",
      curp: props.nomilinea?.CURP || "",
      rfc: props.nomilinea?.RFC || "",
      entry_day: props.entry_day || "",
      entry_day_nom: props.nomilinea?.FechaIngreso || "",
      daily_salary: props.nomilinea?.Salario || "",
      //imss
      date_entry_imss: props.nomilinea?.FechaIMSS || "",
      bank: props.nomilinea?.Banco_ID || "",
      account: props.nomilinea?.Clabe || "",
    };

    return { info };
  },
  data: () => ({
    editing: false,
    bank_options: [],
  }),
  methods: {
    startEditing() {
      this.editing = true;
    },
    async finishEditing() {
      const data = await modifyTalentInformation(
        this.info.id,
        this.info.job_title,
        this.info.curp,
        this.info.rfc,
        this.info.entry_day_nom,
        this.info.date_entry_imss,
        this.info.bank,
        this.info.account
      );
      this.editing = false;
    },
  },
  async mounted() {
    const data = await getNomilineaOptions();
    this.bank_options = data.Banco_ID;
  },
});
