
import { defineComponent, PropType } from "vue";
import UserCard from "@/components/UserCard.vue";
import { TalentDisplay } from "@/types/talentDisplay.interface";

export default defineComponent({
  name: "Incoming Talent",
  components: {
    UserCard,
  },
  props: {
    talent: Object as PropType<TalentDisplay>,
    exchange_rate: Number,
  },
  data: () => ({
    date: "",
  }),
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
});
