
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
//import PaginationNav from "@/components/PaginationNav.vue";
import Paginator from "@/components/paginator/index.vue";
import "../styles/pagination.css";
import DateFormatter from "@/components/DateFormatter.vue";
import EmptyState from "@/components/EmptyState.vue";
import ErrorState from "@/components/ErrorState.vue";
import Spinner from "@/components/Spinner.vue";
import { PayrollPeriod } from "@/types/payrollPeriod.interface";
import { getPayrollPeriods } from "@/components/api/payroll.api";

export default defineComponent({
  name: "Payrolls",
  components: {
    Navbar,
    //PaginationNav,
    DateFormatter,
    EmptyState,
    Spinner,
    ErrorState,
    Paginator,
  },
  data: () => ({
    payrolls: [] as Array<PayrollPeriod>,
    pagination: {} as any,
    nextPage: 1,
    loading: false,
    error: false,
  }),
  methods: {
    updateHandler(e: any) {
      this.nextPage = e;
      this.refreshPayrolls();
    },
    async refreshPayrolls() {
      this.loading = true;
      const data = await getPayrollPeriods(this.nextPage);
      if (data) {
        this.payrolls = data.payroll_periods;
        this.pagination = data.meta.pagination;
        this.nextPage = data.meta.pagination.current_page;
      } else {
        this.error = true;
      }
      this.loading = false;
    },
    isActive(endDate: string): boolean {
      const now = new Date();
      const then = new Date(endDate);

      return then.getTime() > now.getTime();
    },
  },
  mounted() {
    this.refreshPayrolls();
  },
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
    activePayrolls() {
      // return this.payrolls.filter(payroll => payroll.stripe_invoice_status !== "not_created");
      return this.payrolls;
    },
  },
});
