
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import ErrorState from "@/components/ErrorState.vue";
import ModalReimbursements from "@/components/Reimbursements/ModalReimbursement.vue";
import PendingReimbursement from "@/components/Reimbursements/PendingReimbursement.vue";
import ApprovedReimbursement from "@/components/Reimbursements/ApprovedReimbursement.vue";
import DeniedReimbursement from "@/components/Reimbursements/DeniedReimbursements.vue";
import { getReimbursements } from "@/components/api/reimbursements.api";
import { Reimbursement } from "@/types/reimbursement.interface";

export default defineComponent({
  name: "Reimbursements",
  components: {
    Navbar,
    ModalReimbursements,
    PendingReimbursement,
    ApprovedReimbursement,
    DeniedReimbursement,
    Spinner,
    EmptyState,
    ErrorState,
  },
  data: () => ({
    allReimbursements: [] as Array<Reimbursement>,
    pending: [] as Array<Reimbursement>,
    approved: [] as Array<Reimbursement>,
    done: [] as Array<Reimbursement>,
    denied: [] as Array<Reimbursement>,
    searchQuery: "",
    openFilter: false,
    nextUnlockedPayroll: {},
    openModal: false,
    filters: [
      "all categories",
      "travel",
      "food_and_beverages",
      "office_supplies",
      "other",
    ],
    selectedFilter: "all categories",
    loading: false,
    error: false,
  }),
  methods: {
    sortReimbursements(reimbursements: Array<Reimbursement>) {
      this.pending = [];
      this.approved = [];
      this.done = [];
      this.denied = [];
      for (let i = 0; i < reimbursements.length; i++) {
        const reimbursement = reimbursements[i];
        const status = reimbursement.status;
        if (
          reimbursement.detail === this.selectedFilter ||
          this.selectedFilter === "all categories"
        ) {
          if (status === "pending") {
            this.pending.push(reimbursement);
          } else if (status === "approved") {
            this.approved.push(reimbursement);
          } else if (status === "done") {
            this.done.push(reimbursement);
          } else if (status === "denied") {
            this.denied.push(reimbursement);
          }
        }
      }
    },
    filterReimbursements(detail: string) {
      this.selectedFilter = detail;
      this.sortReimbursements(this.allReimbursements);
      this.openFilter = false;
    },
    closeDropdown(event: any) {
      if (event.target.className !== "mr-4 capitalize") {
        this.openFilter = false;
      }
    },
    filterSearch() {
      const data = this.allReimbursements.filter((reimbursement) => {
        var name =
          reimbursement.user.first_name
            .normalize("NFKD")
            .replace(/[^\w]/g, "") +
          " " +
          reimbursement.user.last_name.normalize("NFKD").replace(/[^\w]/g, "");
        return (
          reimbursement.detail
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          reimbursement.title
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          (reimbursement.original_total / 100)
            .toString()
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          name
            .split("_")
            .join(" ")
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
      this.sortReimbursements(data);
    },
    async refreshReimbursements() {
      this.loading = true;
      this.error = false;
      this.$store.dispatch("setRefresh", false);
      this.pending = [];
      this.approved = [];
      this.done = [];
      this.denied = [];
      const data = await getReimbursements();
      if (data) {
        this.allReimbursements = data.reimbursements;
        this.sortReimbursements(data.reimbursements);
        this.nextUnlockedPayroll = data.meta?.next_unlocked_payroll;
      } else {
        this.error = true;
      }
      this.loading = false;
    },
  },
  computed: {
    refresh() {
      return this.$store.getters.getRefresh;
    },
  },
  watch: {
    openModal(newVal) {
      if (newVal === false) {
        this.refreshReimbursements();
      }
    },
    refresh(newVal) {
      if (newVal) {
        this.refreshReimbursements();
      }
    },
  },
  mounted() {
    this.refreshReimbursements();
  },
});
