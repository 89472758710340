
import { defineComponent, PropType, ref } from "vue";
import Bullets from "@/components/Talent/Bullets.vue";

export default defineComponent({
  name: "Position",
  components: {
    Bullets,
  },
  props: {
    talent: {
      type: Object as PropType<any>,
      default: () => ({} as any),
    },
  },
  setup(props) {
    const displayRespAndReqs = ref({
      responsibilities: props.talent.position?.responsibilities,
      requirements: props.talent.position?.requirements,
    });

    return { displayRespAndReqs };
  },
});
