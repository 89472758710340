import { GetterTree } from "vuex";
import { RootState } from "../types";
import { AuthState } from "./types";

export const getters: GetterTree<AuthState, RootState> = {
  getSessionToken(state): string {
    return state.token;
  },
  getLogin(state): boolean {
    return state.logged_in;
  },
  getChangePasswordToken(state): string {
    return state.change_password_token;
  },
  getChangePasswordEmail(state): string {
    return state.change_password_email;
  },
};
