/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { UserProfile } from "./types";
import { getProfile } from "@/components/api/users.api";

export const actions: ActionTree<UserProfile, RootState> = {
  async getProfile({ commit }) {
    const data = await getProfile();
    if (data) {
      commit("PROFILE_FULLFILED", data);
    }
  },
};
