
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import EmptyState from "@/components/EmptyState.vue";
import Spinner from "@/components/Spinner.vue";
import ErrorState from "@/components/ErrorState.vue";
import ActiveTalent from "@/components/TalentCards/ActiveTalent.vue";
import IncomingTalent from "@/components/TalentCards/IncomingTalent.vue";
import InactiveTalent from "@/components/TalentCards/InactiveTalent.vue";
import { TalentDisplay } from "@/types/talentDisplay.interface";
import { getTalents } from "@/components/api/talent.api";

export default defineComponent({
  name: "Talents",
  components: {
    Navbar,
    EmptyState,
    Spinner,
    ErrorState,
    ActiveTalent,
    IncomingTalent,
    InactiveTalent,
  },
  data: () => ({
    allTalent: [] as Array<TalentDisplay>,
    incomingMembers: [] as Array<TalentDisplay>,
    activeMembers: [] as Array<TalentDisplay>,
    inactiveMembers: [] as Array<TalentDisplay>,
    meta: {} as any,
    searchQuery: "",
    openFilter: false,
    filters: ["All Status", "onboarding", "active", "inactive"],
    selectedFilter: "All Status",
    loading: false,
    error: false,
  }),
  methods: {
    sortTalent(talent: Array<TalentDisplay>) {
      this.incomingMembers = [];
      this.activeMembers = [];
      this.inactiveMembers = [];
      for (let i = 0; i < talent.length; i++) {
        if (talent[i].status === "incoming") {
          this.incomingMembers.push(talent[i]);
        } else if (talent[i].status === "active") {
          this.activeMembers.push(talent[i]);
        } else if (talent[i].status === "inactive") {
          this.inactiveMembers.push(talent[i]);
        }
      }
    },
    filterSearch() {
      const data = this.allTalent.filter((talent) => {
        const name = talent.first_name + " " + talent.last_name;
        return name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
      this.sortTalent(data);
    },
    filterTalent(status: string) {
      this.selectedFilter = status;
      this.sortTalent(this.allTalent);
      if (status === "onboarding") {
        this.activeMembers = [];
        this.inactiveMembers = [];
      } else if (status === "active") {
        this.incomingMembers = [];
        this.inactiveMembers = [];
      } else if (status === "inactive") {
        this.incomingMembers = [];
        this.activeMembers = [];
      }
      this.openFilter = false;
    },
  },
  async mounted() {
    this.loading = true;
    const data = await getTalents();
    if (data) {
      this.meta = data.meta;
      this.allTalent = data.talents;
      this.$store.dispatch("setTalentIds", data.meta.user_ids);
      this.sortTalent(data.talents);
    } else {
      this.error = true;
    }
    this.loading = false;
  },
});
