
import { defineComponent } from "vue";
import { useStore } from "vuex";
import TextField from "@/components/textField/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Login",
  components: {
    TextField,
  },
  setup() {
    const store = useStore();
    const schema = yup.object({
      email: yup.string().required().email(),
      password: yup.string().required().min(8),
    });
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
    });
    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const onSubmit = handleSubmit((values) => {
      const user = {
        email: values.email,
        password: values.password,
      };
      login(user);
    });

    //Functions
    async function login(user: any) {
      const response = await store.dispatch("loginUser", user);
      if (response) {
        createToastAxelerate("Login", response.errors[0], "danger");
      }
    }

    return { email, password, meta, onSubmit };
  },
  data: () => ({
    user: {
      email: "",
      password: "",
    },
    errors: {
      email: {
        error: false,
        message: "",
      },
      password: {
        error: false,
        message: "",
      },
    },
  }),
  methods: {
    getToken() {
      this.$store.dispatch("loginUser", this.user);
    },
  },
  computed: {
    loginError() {
      return this.$store.getters.login_error;
    },
  },
});
