
import { defineComponent, PropType } from "vue";
import { updateReimbursement } from "@/components/api/reimbursements.api";
import { getNextPayrollDates } from "@/components/api/payroll.api";
import { Reimbursement } from "@/types/reimbursement.interface";
import UserCard from "@/components/UserCard.vue";
// import DateFormatter from "@/components/DateFormatter.vue";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Modal Decision",
  components: {
    UserCard,
    // DateFormatter,
  },
  props: {
    openDecision: Boolean,
    reimbursement: Object as PropType<Reimbursement>,
    nextUnlockedPayroll: Object,
  },
  emits: ["update:openDecision"],
  data: () => ({
    decision: "",
    message: "",
    upvoteBtn: {
      color: "transparent",
      img: "thumbs-up",
      text: "black",
      selected: false,
    },
    downvoteBtn: {
      color: "transparent",
      img: "thumbs-down",
      hover: "gray-300",
      text: "black",
      selected: false,
    },
    payment_date: "",
    showTooltip: false,
    loading: false,
  }),
  methods: {
    upvote() {
      if (!this.upvoteBtn.selected) {
        this.decision = "approved";
        this.upvoteBtn.selected = true;
        this.downvoteBtn.selected = false;
        this.upvoteBtn.color = "green-100";
        this.upvoteBtn.text = "green-500";
        this.upvoteBtn.img = "thumbs-up-green";
        this.downvoteBtn.text = "gray-400";
        this.downvoteBtn.img = "thumbs-down-gray";
        this.downvoteBtn.color = "transparent";
      } else {
        this.resetBtns();
      }
    },
    downvote() {
      if (!this.downvoteBtn.selected) {
        this.decision = "denied";
        this.downvoteBtn.selected = true;
        this.upvoteBtn.selected = false;
        this.downvoteBtn.color = "red-100";
        this.downvoteBtn.text = "red-500";
        this.downvoteBtn.img = "thumbs-down-red";
        this.upvoteBtn.text = "gray-400";
        this.upvoteBtn.img = "thumbs-up-gray";
        this.upvoteBtn.color = "transparent";
      } else {
        this.resetBtns();
      }
    },
    resetBtns() {
      this.decision = "";
      this.upvoteBtn = {
        color: "transparent",
        img: "thumbs-up",
        text: "black",
        selected: false,
      };
      this.downvoteBtn = {
        color: "transparent",
        img: "thumbs-down",
        hover: "gray-300",
        text: "black",
        selected: false,
      };
    },
    async decide() {
      this.loading = true;
      const id = this.reimbursement?.id || "";
      const note = this.reimbursement?.note
        ? this.reimbursement.note
        : this.message;
      const data = await updateReimbursement(this.message, this.decision, id);
      if (data) {
        createToastAxelerate(
          "Reimbursement request",
          "The reimbursement request has been updated successfully",
          "success"
        );
        this.$store.dispatch("setRefresh", true);
      } else {
        createToastAxelerate(
          "Reimbursement request",
          "something went wrong",
          "danger"
        );
      }
      this.closeModal();
      this.loading = true;
    },
    closeModal() {
      this.$emit("update:openDecision", false);
    },
    async setPaymentDate() {
      const id = this.reimbursement?.user.id as string;
      const data = await getNextPayrollDates(id);
      if (data) {
        if (data.current.length > 0) {
          const end = new Date(data.current[0].end_date);
          const now = new Date();
          if (end < now) {
            this.payment_date = data.current[0].end_date;
          } else if (data.upcoming.length > 0) {
            this.payment_date = data.upcoming[0].end_date;
          }
        }
      }
    },
  },
  computed: {
    isSelected() {
      return (
        this.upvoteBtn.selected || (this.downvoteBtn.selected && this.message)
      );
    },
  },
  mounted() {
    this.setPaymentDate();
  },
});
