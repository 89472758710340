import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between mt-4 bg-gray-200 rounded-2xl p-8" }
const _hoisted_2 = { class: "w-1/3" }
const _hoisted_3 = { class: "flex items-center w-1/4" }
const _hoisted_4 = { class: "flex items-center w-1/4" }
const _hoisted_5 = { class: "flex items-center justify-between w-1/5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCard = _resolveComponent("UserCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UserCard, {
        id: _ctx.talent.id,
        firstName: _ctx.talent.first_name,
        lastName: _ctx.talent.last_name,
        position: _ctx.talent.job.title
      }, null, 8, ["id", "firstName", "lastName", "position"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, "Start day: " + _toDisplayString(_ctx.talent.entry_day), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, "Inactive day: " + _toDisplayString(_ctx.talent.inactive_day), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", null, "$" + _toDisplayString((_ctx.talent.salary / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })), 1)
    ])
  ]))
}