
import { defineComponent, PropType, ref } from "vue";
import { Talent } from "@/types/talent.interface";
import { Benefit } from "@/types/benefit.interface";

export default defineComponent({
  name: "Payment breakdown",
  props: {
    talent: Object as PropType<Talent>,
  },
  setup(props) {
    const benefitsTotal: any = [];
    props.talent?.meta.last_payroll.benefits.forEach((benefit: any) => {
      benefitsTotal.push(benefit.value);
    });
    const reducer = (accumulator: any, currentValue: any) =>
      accumulator + currentValue;
    const totalBenefits = benefitsTotal.reduce(reducer);

    const totalComp =
      props.talent?.meta.last_payroll?.gross_salary +
      totalBenefits +
      props.talent?.meta.last_payroll?.christmas_bonus +
      props.talent?.meta.last_payroll?.axelerate_percent;

    /* const info = {
      axelerate_percent: props.talent?.meta.last_payroll?.axelerate_percent || 0,
      benefits: props.talent?.meta.last_payroll?.benefits || [] as Array<Benefit>,
      benefits_total: totalBenefits || 0,
      christmas_bonus: props.talent?.meta.last_payroll?.christmas_bonus || 0,
      employer_taxes: props.talent?.meta.last_payroll?.employer_tax || 0,
      gross_salary: props.talent?.meta.last_payroll?.gross_salary || 0,
      salary: props.talent?.talent.salary || {},
      total_comp: totalComp || 0,
    }; */
    const info = ref({
      gross_salary:
        props.talent?.meta.complete_payroll.payment_breakdown.gross_salary || 0,
      net_salary: props.talent?.talent.salary.monthly || 0,
      employer_taxes:
        props.talent?.meta.complete_payroll.payment_breakdown.employer_tax || 0,
      benefits: props.talent?.talent?.job?.benefits || ([] as Array<any>),
      benefits_total:
        props.talent?.meta.complete_payroll.payment_breakdown.benefits_total ||
        0,
      total_comp:
        props.talent?.meta.complete_payroll.payment_breakdown.total_comp || 0,
      axelerate_percent:
        props.talent?.meta.complete_payroll.payment_breakdown
          .axelerate_percent || 0,
      christmas_bonus:
        props.talent?.meta.complete_payroll.payment_breakdown.christmas_bonus ||
        0,
      salary: props.talent?.talent?.salary || {},
      job_id: props.talent?.talent?.job?.id || "",
    });

    const exchange_rate = props.talent?.meta.mxn_to_usd_exchange_rate || 0;

    return { info, exchange_rate };
  },
  data: () => ({
    editing: false,
    newBenefit: {
      name: "",
      amount: 0,
    },
    newAddon: {
      name: "",
      amount: 0,
    },
  }),
  methods: {
    addBenefit() {
      this.info.benefits.push({
        name: this.newBenefit.name,
        amount: this.newBenefit.amount,
      });
      this.newBenefit.name = "";
      this.newBenefit.amount = 0;
    },
    startEditing() {
      this.editing = true;
      for (let i = 0; i < this.info.benefits.length; i++) {
        this.info.benefits[i].amount /= 100;
      }
      /*for (let i = 0; i < this.info.benefits.length; i++) {
        this.info.addons[i].value /= 100;
      }*/
    },
    finishEditing() {
      this.editing = false;
      for (let i = 0; i < this.info.benefits.length; i++) {
        this.info.benefits[i].amount *= 100;
      }
      /*for (let i = 0; i < this.info.benefits.length; i++) {
        this.info.addons[i].value *= 100;
      }*/
      // PUT /talent/${id}
    },
  },
});
