
import { defineComponent } from "vue";
import { addTimelineComment } from "@/components/api/timeline.api";

export default defineComponent({
  name: "Modal Clarification",
  props: {
    newComment: Boolean,
    timelineId: Number,
  },
  emits: ["update:newComment"],
  data: () => ({
    openModal: false,
    message: "",
  }),
  methods: {
    async addComment() {
      if (this.timelineId) {
        const data = await addTimelineComment(
          String(this.timelineId),
          this.message
        );
        if (data) {
          this.$emit("update:newComment", true);
          this.openModal = false;
        }
      }
    },
  },
});
