import { MutationTree } from "vuex";
import { AuthState } from "./types";

export enum SessionMutations {
  SET_TOKEN = "SET_TOKEN",
  SET_LOGIN = "SET_LOGIN",
  SET_CHANGEPASSWORDTOKEN = "SET_CHANGEPASSWORDTOKEN",
  SET_CHANGEPASSWORDEMAIL = "SET_CHANGEPASSWORDEMAIL",
}

export const mutations: MutationTree<AuthState> = {
  [SessionMutations.SET_TOKEN](state, payload: string) {
    state.token = payload;
  },
  [SessionMutations.SET_LOGIN](state, payload: boolean) {
    state.logged_in = payload;
  },
  [SessionMutations.SET_CHANGEPASSWORDTOKEN](state, payload: string) {
    state.change_password_token = payload;
  },
  [SessionMutations.SET_CHANGEPASSWORDEMAIL](state, payload: string) {
    state.change_password_email = payload;
  },
};
