import { client } from "./baseclient";

export async function getAccountMembers() {
  try {
    const { data } = await client.get("/account_members?platform=workforce");
    return data;
  } catch (err) {
    return null;
  }
}

export async function addAccountMembers(emails: Array<string>) {
  const body = {
    account_members: emails,
    //platform: "workforce",
    roles: ["workforce_admin"],
  };
  try {
    const { data } = await client.post("/account_members", body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function removeAccountMember(id: string) {
  try {
    const { data } = await client.delete(`/account_members/${id}`);
    return data;
  } catch (err) {
    return null;
  }
}
