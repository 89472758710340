import store from "@/store";

import { client } from "./baseclient";

export async function getPayrollPeriods(page?: number) {
  const company_id = store.getters.getCompanyId;
  try {
    let query = 1;
    if (typeof page !== "undefined") {
      query = page;
    }
    const { data } = await client.get(
      `/companies/${company_id}/payroll_periods?page=${query}`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getPayroll(payroll_id: string) {
  const company_id = store.getters.getCompanyId;
  try {
    const { data } = await client.get(
      `/companies/${company_id}/payroll_periods/${payroll_id}`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getNextPayrollDates(user_id: string) {
  try {
    const { data } = await client.get(`users/${user_id}/payroll_periods`);
    return data;
  } catch (err) {
    return null;
  }
}
