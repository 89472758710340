
import { defineComponent, PropType } from "vue";
import DateFormatter from "@/components/DateFormatter.vue";

export default defineComponent({
  name: "Card Extra Charge",
  components: {
    DateFormatter,
  },
  props: {
    extraCharge: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    return {};
  },
});
