
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref } from "vue";
import VuePdfEmbed from "vue-pdf-embed";

const PdfView = defineComponent({
  components: {
    VuePdfEmbed,
  },
  props: {
    file: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup() {
    const isLoading = ref(false);
    const page = ref(1);
    const count = ref(1);
    const pdfRef = ref<InstanceType<typeof VuePdfEmbed>>();

    const handleDocumentRender = () => {
      isLoading.value = false;
      count.value = pdfRef.value?.pageCount as number;
    };
    const handlePasswordRequest = (callback: any, retry: any) => {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    };
    return {
      isLoading,
      page,
      handleDocumentRender,
      handlePasswordRequest,
      pdfRef,
      count,
    };
  },
});

export default PdfView;
