
import { defineComponent } from "vue";
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import ErrorState from "@/components/ErrorState.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { getTimeline } from "@/components/api/timeline.api";
import { Timeline } from "@/types/timeline.interface";

export default defineComponent({
  name: "Timeline",
  components: {
    Spinner,
    EmptyState,
    ErrorState,
    DateFormatter,
  },
  props: {
    type: String,
    value: String,
  },
  data: () => ({
    timeline: [] as Array<Timeline>,
    loading: false,
    error: false,
  }),
  mounted() {
    this.loadTimeline();
  },
  methods: {
    async loadTimeline() {
      this.loading = true;
      this.error = false;
      let data = {} as any;
      if (this.type && this.value) {
        data = await getTimeline(this.type, this.value);
      } else {
        data = await getTimeline();
      }
      if (data) {
        this.timeline = data.time_lines;
      } else {
        this.error = true;
      }
      this.loading = false;
    },
    timeAgo(date: string) {
      const old = new Date(date);
      const curr = new Date();
      const diff = Math.abs(old.getTime() - curr.getTime()) / 1000;
      let ans = "";

      if (Math.floor(diff / 604800) > 0) {
        ans = Math.floor(diff / 604800).toString() + " week(s) ago";
      } else if (Math.floor(diff / 86400) > 0) {
        ans = Math.floor(diff / 86400).toString() + " day(s) ago";
      } else if (Math.floor(diff / 3600) % 24 > 0) {
        ans = (Math.floor(diff / 3600) % 24).toString() + " hour(s) ago";
      } else {
        ans = "Now";
      }

      return ans;
    },
    convertString(original: string): string {
      const name = original.toLowerCase();
      const names = name.split(" ");
      for (let i = 0; i < names.length; i++) {
        names[i].toLowerCase();
        names[i] = names[i][0].toUpperCase() + names[i].substr(1);
      }
      return names.join(" ");
    },
  },
});
