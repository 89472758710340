import "./styles/app.css";

import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

console.log("Version:", process.env.VUE_APP_VERSION);
createApp(App).use(store).use(router).mount("#app");
