import { client } from "./baseclient";

export async function getPaymentMethods() {
  try {
    const { data } = await client.get("/payment_methods");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getBilling() {
  try {
    const { data } = await client.get("/billing");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getStripeCustomer() {
  try {
    const { data } = await client.get("/stripe/customer");
    return data;
  } catch (err) {
    return null;
  }
}

export async function stripeAddCard() {
  try {
    const { data } = await client.get("/stripe/add_card");
    return data.url;
  } catch (err) {
    return null;
  }
}

export async function stripeRemoveCard(cardId: string) {
  try {
    const { data } = await client.get(`/stripe/remove_card/${cardId}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function stripeUpdateDefaultCard(cardId: string) {
  try {
    const { data } = await client.get(`/stripe/update_default_card/${cardId}`);
    return data;
  } catch (err) {
    return null;
  }
}
