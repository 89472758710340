
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import UserCard from "@/components/UserCard.vue";
import Spinner from "@/components/Spinner.vue";
import ErrorState from "@/components/ErrorState.vue";
import ModalClarification from "@/components/Payroll/ModalClarification.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import CardExtraCharge from "@/components/cardExtraCharge/index.vue";
import { getPayroll } from "@/components/api/payroll.api";
import { Payroll } from "@/types/payroll.interface";

export default defineComponent({
  name: "Payroll",
  components: {
    Navbar,
    UserCard,
    ModalClarification,
    DateFormatter,
    Spinner,
    ErrorState,
    CardExtraCharge,
  },
  data: () => ({
    timeline: [] as Array<any>,
    payroll: {} as any,
    meta: {
      total_users: 0,
      payrolls_total: 0,
      payrolls_exchange_rate_mxn: 0,
      payroll_stripe_handling_fee: 0,
      default_payment_method: "",
    },
    hover: "",
    hoverBonus: "",
    loading: false,
    error: false,
    newComment: false,
    showTooltip: false,
    newMargin: "",
  }),
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
  watch: {
    newComment(newVal) {
      if (newVal) {
        this.refreshPayroll();
      }
    },
  },
  methods: {
    async refreshPayroll() {
      this.loading = true;
      this.newComment = false;
      const id = this.$route.params.id as string;
      const data = await getPayroll(id);
      if (data) {
        this.payroll = data.payroll_period;
        this.timeline = data.payroll_period.time_lines;
        this.meta = data.meta;
      } else {
        this.error = true;
      }
      this.loading = false;
    },
    showBenefits(id: string) {
      this.hover = id;
      const margin = document.getElementById("tooltip")?.clientHeight as number;
      if (margin) {
        this.newMargin = String(margin);
      }
    },
    showBonusesTooltip(id: string) {
      this.hoverBonus = id;
    },
  },
  mounted() {
    this.refreshPayroll();
  },
});
