
import { defineComponent } from "vue";

export default defineComponent({
  name: "User Card",
  props: {
    firstName: String,
    lastName: String,
    image: String,
    position: String,
    salary: Number,
    id: String,
  },
  methods: {
    convertString(original: string): string {
      if (original) {
        const name = original.toLowerCase();
        const names = name.split(" ");
        for (let i = 0; i < names.length; i++) {
          names[i].toLowerCase();
          if (names[i]) {
            names[i] = names[i][0].toUpperCase() + names[i].substr(1);
          }
        }
        return names.join(" ");
      }
      return "";
    },
  },
  computed: {
    initials() {
      let init = "";
      if (this.firstName && this.lastName) {
        init += this.firstName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        init += this.lastName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      } else if (this.firstName) {
        init += this.firstName[0]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (this.firstName?.length > 1) {
          init += this.firstName[1]
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        }
      }
      return init;
    },
    color() {
      //const colors = ["red", "blue", "green", "purple", "pink", "orange",];
      const sysColors = ["primary", "primary-dark"];
      const rand = Math.floor(Math.random() * sysColors.length);

      return sysColors[rand];
    },
  },
});
