
import { defineComponent } from "vue";
import * as yup from "yup";
import { setPassword } from "@/components/api/users.api";

export default defineComponent({
  name: "Reset Password",
  data: () => ({
    loading: false,
    errors: {
      token: false,
      validation: false,
      matching: false,
      api: false,
    },
    passwords: {
      password: "",
      confirmation: "",
    },
    step: 1,
  }),
  props: {
    token: String,
  },
  methods: {
    async validatePasswords() {
      const schema = yup.object().shape({
        password: yup
          .string()
          .min(8)
          .matches(/[A-Z]+/)
          .matches(/[^A-Za-z0-9]+/)
          .required(),
      });
      return await schema.isValid({ password: this.passwords.password });
    },
    async setNewPassword() {
      this.errors.validation = false;
      this.errors.matching = false;
      this.errors.api = false;
      const valid = await this.validatePasswords();
      const match = this.passwords.password === this.passwords.confirmation;
      if (valid && match && this.token) {
        const token = await setPassword(
          this.token,
          this.passwords.password,
          this.passwords.confirmation
        );
        if (token) {
          this.step = 2;
        } else {
          this.errors.api = true;
        }
      } else if (!valid && match) {
        this.errors.validation = true;
      } else {
        this.errors.matching = true;
      }
    },
  },
  mounted() {
    if (!this.token) {
      this.errors.token = true;
    }
  },
});
