
import { ref, defineComponent, onMounted, watch } from "vue";
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import ErrorState from "@/components/ErrorState.vue";
import UserCard from "@/components/UserCard.vue";
import TextField from "@/components/textField/index.vue";
import ModalEmergencyContacts from "@/components/Talent/ModalEmergencyContacts.vue";
import { EmergencyContact } from "@/types/emergencyContact.interface";
import {
  getEmergencyContacts,
  editEmergencyContact,
  deleteEmergencyContact,
  makePrimaryEmergenyContact,
} from "@/components/api/talent.api";
import { onClickOutside } from "@vueuse/core";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";

export default defineComponent({
  name: "Emergency Contacts",
  components: {
    Spinner,
    EmptyState,
    ErrorState,
    UserCard,
    TextField,
    ModalEmergencyContacts,
  },
  props: {
    talent_id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    //Variables
    const contacts = ref<Array<EmergencyContact>>([]);
    const openModal = ref(false);
    const openDropdown = ref("");
    const dropdown = ref(null);
    const loading = ref(false);
    const editing = ref(false);
    const contact_id = ref("");
    const error = ref(false);

    //Validation
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
      phone: yup.string().required().label("Phone"),
      relationship: yup.string().required().label("Relationship"),
      address: yup.string().label("Address"),
    });

    const { meta, handleSubmit, errors } = useForm({
      initialValues: {
        first_name: "",
        last_name: "",
        phone: "",
        relationship: "",
        address: "",
      },
      validationSchema: schema,
    });

    let { value: first_name } = useField("editing.first_name");
    let { value: last_name } = useField("editing.last_name");
    let { value: phone } = useField("editing.phone");
    let { value: relationship } = useField("editing.relationship");
    let { value: address } = useField("editing.address");

    const onSubmit = handleSubmit((values) => {
      finishEditing();
    });

    //Functions
    async function getContacts() {
      loading.value = true;
      const data = await getEmergencyContacts(props.talent_id);
      if (data) {
        contacts.value = data.emergency_contacts;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    function startEditing(idx: number) {
      editing.value = true;
      contact_id.value = contacts.value[idx].id;
      first_name.value = contacts.value[idx].first_name;
      last_name.value = contacts.value[idx].last_name;
      phone.value = contacts.value[idx].phone;
      relationship.value = contacts.value[idx].relationship;
      address.value = contacts.value[idx].address;
    }

    async function finishEditing() {
      loading.value = true;
      const body = {
        id: contact_id.value,
        first_name: first_name.value,
        last_name: last_name.value,
        relationship: relationship.value,
        phone: phone.value,
        address: address.value,
      } as EmergencyContact;

      const data = await editEmergencyContact(props.talent_id, body);
      if (data) {
        editing.value = false;
        first_name.value = "";
        last_name.value = "";
        phone.value = "";
        relationship.value = "";
        address.value = "";
        getContacts();
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    async function deleteContact(id: string) {
      const data = await deleteEmergencyContact(props.talent_id, id);
      if (data) {
        getContacts();
      } else {
        error.value = true;
      }
      openDropdown.value = "";
    }

    async function makePrimary(id: string) {
      const data = await makePrimaryEmergenyContact(props.talent_id, id);
      if (data) {
        getContacts();
      } else {
        error.value = true;
      }
      openDropdown.value = "";
    }

    function showDropdown(id: string) {
      openDropdown.value = id;
    }

    onClickOutside(dropdown, (event) => {
      openDropdown.value = "";
    });

    watch(openModal, (curr, old) => {
      if (curr === false) {
        getContacts();
      }
    });

    onMounted(() => {
      getContacts();
    });

    return {
      contacts,
      openModal,
      loading,
      error,
      editing,
      first_name,
      last_name,
      phone,
      relationship,
      address,
      openDropdown,
      dropdown,
      showDropdown,
      startEditing,
      onSubmit,
      errors,
      deleteContact,
      makePrimary,
    };
  },
});
