import axios from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "Content-Type": "application/json",
    "X-Platform": "",
  },
});

client.interceptors.request.use((config: any) => {
  const token = cookies.get("token");
  if (token) {
    config.headers.authorization = token;
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      cookies.remove("token", "/", ".axelerate.io");
      localStorage.removeItem("token");
      window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
    }
    return error;
  }
);

/**
 * NOTE: We dinaymically set the Auhtorization header to prevent issues where
 *       client is instantiated pointing an undefined value for the Authorization
 *       header and the user had to refresh the page to user the correct value.
 *       This is used once after the use successfully signs in to the app.
 */
export function setAuthorizationHeader(jwt: string) {
  client.defaults.headers.common["Authorization"] = jwt;
}
