
import { defineComponent } from "vue";
import * as yup from "yup";
import { recoverPassword } from "@/components/api/users.api";

export default defineComponent({
  name: "Forgot Password",
  data: () => ({
    emailSent: false,
    email: "",
    errors: {
      validation: false,
      api: false,
    },
  }),
  methods: {
    async validateEmail() {
      const schema = yup.object().shape({
        email: yup.string().email().required(),
      });
      return await schema.isValid({ email: this.email });
    },
    async sendEmail() {
      this.errors.validation = false;
      this.errors.api = false;
      const valid = await this.validateEmail();
      /*if (valid) {
        this.emailSent = true;
      } else { 
        this.errors.validation = true;
      }*/
      if (valid) {
        const data = await recoverPassword(this.email);
        if (data) {
          this.emailSent = true;
        } else {
          this.errors.api = true;
        }
      } else {
        this.errors.validation = true;
      }
    },
  },
});
