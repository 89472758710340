import { setAuthorizationHeader } from "@/components/api/baseclient";
import {
  editCompany,
  loginUser,
  signupUser,
  getProfile,
} from "@/components/api/users.api";
import router from "@/router";
import { AxiosError } from "axios";
import { ActionTree } from "vuex";
import { useCookies } from "vue3-cookies";
import { RootState } from "../types";
import { UserState } from "./types";

const { cookies } = useCookies();

export const actions: ActionTree<UserState, RootState> = {
  async setUser({ commit }) {
    const token = cookies.get("token");
    const data = await getProfile();
    if (data) {
      commit("SET_USEREMAIL", data.email);
      commit("SET_USERFIRSTNAME", data.first_name);
      commit("SET_USERLASTNAME", data.last_name);
      commit("SET_USERROLE", data.roles);
      commit("SET_TOKEN", token, { root: true });
      commit("SET_LOGIN", true, { root: true });
      commit("SET_USERID", data.id);
      commit("SET_COMPANYID", data.company.id);
      commit("SET_COMPANYNAME", data.company.visible_name);
      commit("SET_SHOWSUGGESTEDACTIONS", true);
      commit("SET_CURRENCY", "usd");
      commit("SET_COMPANY_PRODUCTS", data.company_products);
      commit("SET_PAYMENTS", data.company.payment_methods.data);
    }
  },

  async loginUser({ commit }, user) {
    const data = await loginUser(user.email, user.password);
    if (data.session) {
      const token = data.session.token;
      const uid = data.session.user.id;
      if (token) {
        commit("SET_USEREMAIL", user.email);
        commit("SET_USERFIRSTNAME", data.session.user.first_name);
        commit("SET_USERLASTNAME", data.session.user.last_name);
        commit("SET_USERROLE", data.session.user.roles);
        commit("SET_TOKEN", token, { root: true });
        commit("SET_LOGIN", true, { root: true });
        commit("SET_USERID", uid);
        commit("SET_COMPANYID", data.session.user.company.id);
        commit("SET_COMPANYNAME", data.session.user.company.visible_name);
        commit("SET_SHOWSUGGESTEDACTIONS", true);
        commit("SET_CURRENCY", "usd");
        commit("SET_COMPANY_PRODUCTS", data.session.user.company_products);
        setAuthorizationHeader(token);
        localStorage.setItem("token", token);
        router.push("/");
      }
    } else {
      const error = data as AxiosError;
      return error;
    }
  },

  async changeCompanyName({ commit }, name) {
    try {
      const data = await editCompany(name);
      if (data) {
        commit("SET_COMPANYNAME", data.visible_name);
      }
    } catch (err) {
      return;
    }
  },

  changeCurrency({ commit }, currency) {
    commit("SET_CURRENCY", currency);
  },

  logoutUser({ commit }) {
    cookies.remove("token", "/", ".axelerate.io");
    commit("SET_USEREMAIL", "");
    commit("SET_USERFIRSTNAME", "");
    commit("SET_USERLASTNAME", "");
    commit("SET_USERROLE", "");
    commit("SET_TOKEN", "", { root: true });
    commit("SET_LOGIN", false, { root: true });
    commit("SET_USERID", "");
    commit("SET_COMPANY_PRODUCTS", []);
    commit("SET_COMPANYID", "");
    localStorage.removeItem("token");
    window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
  },

  async signupUser({ commit }, user) {
    try {
      const data = await signupUser(
        user.first_name,
        user.last_name,
        user.email,
        user.password,
        user.company_name,
        user.website,
        user.industry,
        user.phone
      );
      const loginData = await loginUser(user.email, user.password);
      if (loginData && data) {
        commit("SET_USEREMAIL", user.email);
        commit("SET_USERFIRSTNAME", data.session.user.first_name);
        commit("SET_USERLASTNAME", data.session.user.last_name);
        commit("SET_USERROLE", data.session.user.role);
        commit("SET_TOKEN", loginData.session.token, { root: true });
        commit("SET_LOGIN", true, { root: true });
        commit("SET_SHOWSUGGESTEDACTIONS", true);
        commit("SET_COMPANYID", data.session.company_id);
        commit("SET_CURRENCY", "usd");
        localStorage.setItem("token", loginData.session.token);
        router.push("/");
      }
    } catch (err) {
      err;
    }
  },

  stopShowingSuggestions({ commit }) {
    commit("SET_SHOWSUGGESTEDACTIONS", false);
  },
};
