import { client } from "./baseclient";

export async function getTimeline(type?: string, value?: string) {
  let query = "";
  if (typeof type !== undefined && typeof value !== undefined) {
    query = `?${type}=${value}`;
  }
  try {
    const { data } = await client.get(`/time_lines${query}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getTimelineComment(timeline_id: string) {
  try {
    const { data } = await client.get(`/time_lines/${timeline_id}/comments`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addTimelineComment(timeline_id: string, text: string) {
  const body = {
    content: text,
  };
  try {
    const { data } = await client.post(
      `/time_lines/${timeline_id}/comments`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}
