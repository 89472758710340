
import { defineComponent, ref, Ref, onMounted, PropType, computed } from "vue";
import UserCard from "@/components/UserCard.vue";
import TextField from "@/components/textField/index.vue";
import TextArea from "@/components/textArea/index.vue";
import { getTalents } from "@/components/api/talent.api";
import {
  createReimbursement,
  editReimbursement,
} from "@/components/api/reimbursements.api";
import { Reimbursement } from "@/types/reimbursement.interface";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { TalentDisplay } from "@/types/talentDisplay.interface";
import { createToastAxelerate } from "@/utils/toast";
import SimpleSelect from "@/components/simpleSelect/index.vue";

export default defineComponent({
  name: "Modal Reimbursement",
  props: {
    reimbursement: Object as PropType<Reimbursement>,
    openModal: Boolean,
  },
  components: {
    UserCard,
    TextField,
    TextArea,
    SimpleSelect,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    //Validation
    const schema = {
      title: yup.string().required().label("Title"),
      detail: yup.string().required().label("Detail"),
      amount: yup
        .number()
        .typeError("Amount is a required field")
        .required()
        .min(0)
        .label("Amount"),
      currency: yup.string().required().label("Currency"),
      note: yup.string().label("Note"),
    };
    const { handleSubmit, errors, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        title: "",
        detail: "",
        amount: "",
        currency: "",
        note: "",
      },
    });
    const { value: title } = useField("title");
    const { value: detail } = useField("detail");
    const { value: amount } = useField("amount");
    const { value: currency } = useField("currency");
    const { value: note } = useField("note");
    const onSubmit = handleSubmit((values) => {
      const body = {
        ...values,
        vouchers: vouchers.value,
      };
      if (editing) {
        editingReimbiursement(body);
      } else {
        createNewReimbursement(body);
      }
    });

    //Variables
    const loading = ref(false);
    const error = ref(false);
    let editing = false;
    const selectedEmployees = ref(false);
    const selectedEmployeeName = ref("");
    const selectedEmployeeId = ref("");
    const talent: Ref<Array<TalentDisplay>> = ref([]);
    const vouchers: Ref<Array<File>> = ref([]);
    const files: Ref<Array<any>> = ref([]);
    const preview: Ref<Array<string>> = ref([]);
    const blockUserEdit = ref(false);
    const details = [
      { name: "Travel", id: "travel" },
      { name: "Food & Beverage", id: "food_and_beverages" },
      { name: "Office Supplies", id: "office_supplies" },
      { name: "Other", id: "other" },
    ];
    const currencies = [
      { name: "Mexican Peso (MXN)", id: "mxn" },
      { name: "US Dollar (USD)", id: "usd" },
    ];

    //Functions
    function closeModal() {
      if (!props.reimbursement) {
        selectedEmployeeId.value = "";
        selectedEmployeeName.value = "";
        preview.value = [];
        resetForm();
      }
      emit("update:openModal", false);
    }
    async function getTalentsDisplay() {
      const data = await getTalents();
      if (data) {
        talent.value = data.talents;
      }
    }
    function selectEmployee(index: number) {
      selectedEmployeeId.value = talent.value[index].id;
      selectedEmployeeName.value =
        talent.value[index].first_name + " " + talent.value[index].last_name;
      selectedEmployees.value = false; //To close the dropdown
    }
    function uploadFiles(event: any) {
      if (event) {
        for (let i = 0; i < event.target.files.length; i++) {
          preview.value.push(event.target.files[i].name);
          vouchers.value.push(event.target.files[i]);
        }
      }
    }
    async function createNewReimbursement(body: any) {
      const data = await createReimbursement(selectedEmployeeId.value, body);
      if (data) {
        createToastAxelerate(
          "Reimbursement",
          "The reimbursement has been created successfully",
          "success"
        );
        closeModal();
      } else {
        createToastAxelerate("Reimbursement", data.errors[0], "danger");
        closeModal();
        error.value = true;
      }
    }

    function viewReceipt(url: string) {
      window.open(url, "_blank");
    }

    async function editingReimbiursement(body: any) {
      const id = props.reimbursement?.id as string;
      const data = await editReimbursement(id, body);
      if (data) {
        createToastAxelerate(
          "Reimbursement",
          "The reimbursement has been updated successfully",
          "success"
        );
        closeModal();
      } else {
        createToastAxelerate("Reimbursement", data.errors[0], "danger");
        closeModal();
        error.value = true;
      }
    }

    //Computed
    const canSubmit = computed(
      () =>
        selectedEmployeeId.value !== "" &&
        (preview.value.length > 0 || files.value.length > 0)
    );

    //Hooks
    onMounted(() => {
      if (props.reimbursement) {
        blockUserEdit.value = true;
        editing = true;
        selectedEmployeeId.value = props.reimbursement.user.id;
        selectedEmployeeName.value =
          props.reimbursement.user.first_name +
          " " +
          props.reimbursement.user.last_name;
        title.value = props.reimbursement.title;
        detail.value = props.reimbursement.detail;
        amount.value = String(props.reimbursement.original_amount / 100);
        currency.value = props.reimbursement.currency;
        note.value = props.reimbursement.note;
        files.value = props.reimbursement.vouchers;
      } else {
        getTalentsDisplay();
        detail.value = "";
        currency.value = "";
      }
    });

    return {
      selectedEmployeeId,
      selectedEmployeeName,
      title,
      detail,
      amount,
      currency,
      note,
      details,
      currencies,
      files,
      selectedEmployees,
      talent,
      blockUserEdit,
      selectEmployee,
      onSubmit,
      canSubmit,
      errors,
      uploadFiles,
      preview,
      closeModal,
      viewReceipt,
    };
  },
});
