import { Module } from "vuex";
import { RootState } from "../types";
import { ReimbursementsState } from "./types";
import { getters } from "./getter";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: ReimbursementsState = {
  refresh: false,
};

export const reimbursements: Module<ReimbursementsState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
