import { MutationTree } from "vuex";
import { TalentsState } from "./types";

export enum SessionMutations {
  SET_IDS = "SET_IDS",
}

export const mutations: MutationTree<TalentsState> = {
  [SessionMutations.SET_IDS](state, payload: Array<string>) {
    state.ids = payload;
  },
};
