
import { defineComponent } from "vue";
import * as yup from "yup";
import UserCard from "@/components/UserCard.vue";
import Spinner from "@/components/Spinner.vue";
import { changePassword } from "@/components/api/users.api";

export default defineComponent({
  name: "Account Settings",
  components: {
    UserCard,
    Spinner,
  },
  data: () => ({
    currencies: [
      {
        name: "USD - U.S. Dollar",
        value: "usd",
      },
      {
        name: "MXN - Mexican Peso",
        value: "mxn",
      },
    ],
    newCompany: {
      name: "",
    },
    selectedCurr: "",
    passwords: {
      current: "",
      new: "",
      confirmation: "",
    },
    loading: false,
    changePasswordSuccess: false,
    changePasswordError: false,
    length: false,
    casing: false,
    hasNumber: false,
    editCompany: false,
  }),
  methods: {
    editCompanyName() {
      this.$store.dispatch("changeCompanyName", this.newCompany.name);
      this.editCompany = false;
    },
    async changeUserPassword() {
      const data = await changePassword(this.passwords.new);
      if (data) {
        this.changePasswordSuccess = true;
        this.changePasswordError = false;
        this.passwords.new = "";
        this.passwords.confirmation = "";
      } else {
        this.changePasswordError = true;
        this.changePasswordSuccess = false;
      }
    },
  },
  watch: {
    selectedCurr(newVal) {
      this.$store.dispatch("changeCurrency", newVal);
    },
    currency(newVal) {
      this.selectedCurr = newVal;
    },
  },
  computed: {
    firstName() {
      return this.$store.getters.getUserFirstName;
    },
    lastName() {
      return this.$store.getters.getUserLastName;
    },
    email() {
      return this.$store.getters.getEmail;
    },
    companyName() {
      return this.$store.getters.getCompanyName;
    },
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
  async mounted() {
    this.loading = true;
    this.selectedCurr = this.currency;
    this.newCompany.name = this.companyName;
    this.loading = false;
  },
});
