
import { defineComponent } from "vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "TextArea",
  inheritAttrs: false,
  components: {
    Field,
  },
  props: {
    rows: {
      type: String,
      default: "3",
    },
    cols: {
      type: String,
      default: "50",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
});
