
import { defineComponent } from "vue";
import Spinner from "@/components/Spinner.vue";
import EmptyState from "@/components/EmptyState.vue";
import ErrorState from "@/components/ErrorState.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { getPaymentMethods, getBilling } from "@/components/api/billing.api";
import {
  getStripeCustomer,
  stripeAddCard,
  stripeRemoveCard,
  stripeUpdateDefaultCard,
} from "@/components/api/billing.api";
import { PaymentMethod } from "@/types/paymentMethod.interface";
import { Invoice } from "@/types/invoice.interface";

export default defineComponent({
  name: "Billing",
  components: {
    Spinner,
    EmptyState,
    ErrorState,
    DateFormatter,
  },
  data: () => ({
    loading: false,
    cardsLoading: false,
    defaultPaymentMethod: null,
    error: false,
    invoices: [] as Array<Invoice>,
    nextPayment: {
      amount: 0,
      exchange_rate: 0,
      date: "",
    },
    paymentMethods: [] as Array<PaymentMethod>,
  }),
  computed: {
    currency() {
      return this.$store.getters.getCurrency;
    },
  },
  methods: {
    sortCards(cards: Array<PaymentMethod>) {
      const fingerprints = [] as Array<string>;
      for (let i = 0; i < cards.length; i++) {
        if (!fingerprints.includes(cards[i].card?.fingerprint)) {
          fingerprints.push(cards[i].card?.fingerprint);
          this.paymentMethods.push(cards[i]);
        }
      }
    },
    async addCard() {
      const redirect_url = await stripeAddCard();
      window.location.href = redirect_url;
    },
    async getDefaultPaymentMethod() {
      const { customer } = await getStripeCustomer();
      if (customer) {
        this.defaultPaymentMethod =
          customer.invoice_settings?.default_payment_method;
      }
    },
    async removeCard(card: PaymentMethod) {
      const { credit_card_deleted } = await stripeRemoveCard(card.id);
      if (credit_card_deleted) {
        this.paymentMethods = this.paymentMethods.filter(
          (payment) => payment.id !== card.id
        );
        this.getDefaultPaymentMethod();
        if (this.paymentMethods.length) {
          this.setDefaultCard(this.paymentMethods[0]);
        }
      }
    },
    async setDefaultCard(card: PaymentMethod) {
      this.cardsLoading = true;
      const { default_payment_method } = await stripeUpdateDefaultCard(card.id);
      this.defaultPaymentMethod = default_payment_method;
      this.paymentMethods = [];
      const data = await getPaymentMethods();
      this.sortCards(data.data);
      this.cardsLoading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const data = await getPaymentMethods();
    const billing = await getBilling();
    if (data && billing) {
      this.sortCards(data.data);
      this.invoices = billing.stripe_transactions;
      if (billing.next_billing[0]) {
        this.nextPayment.amount = billing.next_billing[0].payrolls_total;
        this.nextPayment.exchange_rate = billing.next_billing[0].exchange_rate;
        this.nextPayment.date = billing.next_billing[0].end_date;
      } else {
        this.nextPayment.amount = 0;
        this.nextPayment.exchange_rate = 0;
        this.nextPayment.date = "";
      }
    } else {
      this.error = true;
    }
    this.getDefaultPaymentMethod();
    this.loading = false;
  },
});
