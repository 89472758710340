
import { defineComponent, PropType, ref, watch } from "vue";
import UserCard from "@/components/UserCard.vue";
import ModalDecision from "@/components/Reimbursements/ModalDecision.vue";
import ModalReimbursement from "@/components/Reimbursements/ModalReimbursement.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { Reimbursement } from "@/types/reimbursement.interface";
import FileSaver from "file-saver";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "Pending Reimbursement",
  components: {
    UserCard,
    ModalDecision,
    ModalReimbursement,
    DateFormatter,
  },
  props: {
    reimbursement: Object as PropType<Reimbursement>,
    nextUnlockedPayroll: Object,
  },
  emits: ["update:setRefresh"],
  setup(props, { emit }) {
    const hover = ref(false);
    const openDecision = ref(false);
    const openModal = ref(false);
    const showTooltip = ref(false);
    const openDropdown = ref("");
    const dropdown = ref(null);

    //Functions
    function downloadVoucher() {
      if (props.reimbursement?.vouchers) {
        for (let i = 0; i < props.reimbursement.vouchers.length; i++) {
          FileSaver.saveAs(props.reimbursement.vouchers[i].url);
        }
      }
      closeDropdown();
    }

    function showDropdown(id: any) {
      openDropdown.value = id;
    }

    onClickOutside(dropdown, (event) => {
      openDropdown.value = "";
    });

    function closeDropdown() {
      openDropdown.value = "";
    }
    //
    watch(openModal, (curr, old) => {
      if (curr === false) {
        emit("update:setRefresh", true);
      }
    });

    return {
      openModal,
      showDropdown,
      dropdown,
      openDropdown,
      downloadVoucher,
      openDecision,
      hover,
      showTooltip,
      closeDropdown,
    };
  },
});
