import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-screen" }
const _hoisted_2 = { class: "flex-grow bg-background shadow-inner" }
const _hoisted_3 = { class: "container flex flex-row items-start py-8" }
const _hoisted_4 = { class: "flex flex-col bg-white w-1/4 shadow rounded-2xl p-4 mr-4" }
const _hoisted_5 = {
  key: 0,
  class: "h-px w-full bg-gray-300 my-2"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex flex-row items-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_AccountSettings = _resolveComponent("AccountSettings")!
  const _component_AccountMembers = _resolveComponent("AccountMembers")!
  const _component_Billing = _resolveComponent("Billing")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Navbar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "flex flex-col"
              }, [
                (i > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.changePage(i)),
                  class: _normalizeClass(["hover:bg-background-secondary rounded p-4 font-semibold text-left", { 'bg-background-secondary': item.selected }])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    (item.selected)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: require(`@/assets/icons/${item.icon}-selected.svg`)
                        }, null, 8, _hoisted_8))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: require(`@/assets/icons/${item.icon}.svg`)
                        }, null, 8, _hoisted_9)),
                    _createElementVNode("span", {
                      class: _normalizeClass(["ml-4 capitalize", { 'text-gray-400': !item.selected }])
                    }, _toDisplayString(item.name.replaceAll("-", " ")), 3)
                  ])
                ], 10, _hoisted_6)
              ]))
            }), 128))
          ]),
          (_ctx.selectedPage === 'account-settings')
            ? (_openBlock(), _createBlock(_component_AccountSettings, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.selectedPage === 'account-members')
            ? (_openBlock(), _createBlock(_component_AccountMembers, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.selectedPage === 'billing')
            ? (_openBlock(), _createBlock(_component_Billing, { key: 2 }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}