
import { defineComponent } from "vue";
import UserCard from "@/components/UserCard.vue";
import InviteMembersModal from "@/components/Settings/InviteMembersModal.vue";
import Spinner from "@/components/Spinner.vue";
import ErrorState from "@/components/ErrorState.vue";
import {
  getAccountMembers,
  removeAccountMember,
} from "@/components/api/accountMembers.api";
import { AccountMembers } from "@/types/accountMembers.interface";

export default defineComponent({
  name: "Account Members",
  components: {
    UserCard,
    InviteMembersModal,
    Spinner,
    ErrorState,
  },
  data: () => ({
    openModal: false,
    toggleActive: true,
    loading: false,
    error: false,
    members: {} as AccountMembers,
  }),
  methods: {
    async removeMember(id: string) {
      this.loading = true;
      const data = await removeAccountMember(id);
      this.members = await getAccountMembers();
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const data = await getAccountMembers();
    if (data) {
      this.members = data.account_members;
    } else {
      this.error = true;
    }
    this.loading = false;
  },
});
