
import { defineComponent, PropType } from "vue";
import UserCard from "@/components/UserCard.vue";
import { TalentDisplay } from "@/types/talentDisplay.interface";

export default defineComponent({
  name: "Inactive Talent",
  components: {
    UserCard,
  },
  props: {
    talent: Object as PropType<TalentDisplay>,
  },
});
