import store from "@/store";
import { AxiosError } from "axios";

import { client } from "./baseclient";

export async function loginUser(email: string, password: string) {
  const credentials = {
    email: email,
    password: password,
  };
  try {
    const { data } = await client.post("/sessions", credentials);
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function confirmEmail(token: string) {
  const body = {
    id: token,
    platform: "workforce",
  };
  try {
    const { data } = await client.put(`/account_confirmation/${body.id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function signupUser(
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  company_name: string,
  website: string,
  industry: string,
  phone: string
) {
  try {
    const body = {
      user: {
        email: email,
        first_name: first_name,
        last_name: last_name,
        password: password,
        password_confirmation: password,
        company_name: company_name,
        website: website,
        industry: industry,
        phone: phone,
        roles: ["admin"],
      },
      platform: "workforce",
    };
    const { data } = await client.post("/users", body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCompleteAccountUser(token: string) {
  try {
    const data = await client.get(`/get_user/${token}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function completeAccount(user_id: string, user: any) {
  try {
    const data = await client.put(`/update_user/${user_id}`, user);
    return data;
  } catch (err) {
    return null;
  }
}

export async function recoverPassword(email: string) {
  try {
    const body = {
      email: email,
      platform: "workforce",
    };
    const { data } = await client.post("/recover_password", body);
    return data.token_id;
  } catch (err) {
    return null;
  }
}

export async function setPassword(
  token_id: string,
  password: string,
  password_confirmation: string
) {
  try {
    const body = {
      token_id: token_id,
      password: password,
      password_confirmation: password_confirmation,
    };
    const { data } = await client.put("/set_password", body);
    return data.session.token;
  } catch (err) {
    return null;
  }
}

export async function changePassword(newPassword: string) {
  const body = {
    user: {
      password: newPassword,
    },
  };
  const id = store.getters.getUserId;
  try {
    const { data } = await client.put(`/users/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getCompany(id: string) {
  try {
    const { data } = await client.get(`companies/${id}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function editCompany(visible_name: string) {
  const company_id = store.getters.getCompanyId;
  const body = {
    company: {
      visible_name,
    },
  };
  try {
    const { data } = await client.put(`/companies/${company_id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function getProfile() {
  try {
    const { data } = await client.get("/profiles");
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}
