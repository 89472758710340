import store from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useCookies } from "vue3-cookies";
import CompleteAccount from "../views/CompleteAccount.vue";
import ConfirmEmail from "../views/ConfirmEmail.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Payroll from "../views/Payroll.vue";
import Payrolls from "../views/Payrolls.vue";
import ProductInfo from "../views/ProductInfo.vue";
import Reimbursements from "../views/Reimbursements.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Settings from "../views/Settings.vue";
import Talent from "../views/Talent.vue";
import Talents from "../views/Talents.vue";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "/product-info",
    name: "Product Info",
    component: ProductInfo,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  /*{
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },*/
  {
    path: "/complete-account",
    name: "Complete Account",
    component: CompleteAccount,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "/talent",
    name: "Talents",
    component: Talents,
  },
  {
    path: "/confirm-email",
    name: "Confirm Email",
    component: ConfirmEmail,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "/settings/:subpage",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/reimbursements",
    name: "Reimbursements",
    component: Reimbursements,
  },
  {
    path: "/payroll",
    name: "Payrolls",
    component: Payrolls,
  },
  {
    path: "/payroll/:id",
    name: "Payroll",
    component: Payroll,
  },
  {
    path: "/talent/:id/:subpage",
    name: "Talent",
    component: Talent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = cookies.get("token");
  const loginPaths = [
    "/login",
    "/forgot-password",
    "/sign-up",
    "/confirm-email",
    "/reset-password",
    "/complete-account",
  ];

  if (token && !store.getters.getUserId) {
    await store.dispatch("setUser");
  }

  if (!loginPaths.includes(to.path) && !token) {
    window.location.replace(process.env.VUE_APP_GLOBAL_LOGIN_URL);
  } else if (loginPaths.includes(to.path) && token) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
