
import { defineComponent, onMounted, Ref, ref } from "vue";
import ModalUploadFile from "@/components/Talent/ModalUploadFile.vue";
import { getDocuments } from "@/components/api/talent.api";
import { useRoute } from "vue-router";
import PdfViewer from "@/components/PdfViewer.vue";

export default defineComponent({
  name: "Files",
  components: {
    ModalUploadFile,
    PdfViewer,
  },
  setup() {
    const files: Ref<Array<any>> = ref([]);
    const openModal = ref(false);
    const selectedFile = ref("");
    const route = useRoute();
    const talent_id = route.params.id as string;

    function selectFile(idx: number, src: string) {
      selectedFile.value = src;
      for (let i = 0; i < files.value.length; i++) {
        if (i === idx) {
          files.value[i].selected = true;
        } else {
          files.value[i].selected = false;
        }
      }
    }
    function getFileType(fileName: string): string {
      const dot = fileName.indexOf(".");
      const type = fileName.substr(dot + 1, 3);
      return type;
    }
    async function getUserDocs() {
      const data = await getDocuments(talent_id);
      files.value = data;
      for (let i = 0; i < files.value.length; i++) {
        files.value[i].file_type = getFileType(files.value[i].identifier);
      }
    }

    onMounted(() => {
      getUserDocs();
    });

    return {
      files,
      openModal,
      selectedFile,
      selectFile,
      talent_id,
    };
  },
});
