
import { defineComponent, Ref, ref } from "vue";
import { submitDocuments } from "@/components/api/talent.api";

export default defineComponent({
  name: "Modal Upload File",
  props: {
    openModal: Boolean,
    talentId: {
      type: String,
      default: "",
    },
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const files: Ref<Array<any>> = ref([]);
    const names: Ref<Array<string>> = ref([]);
    const loading = ref(false);
    const error = ref(false);

    function closeModal() {
      emit("update:openModal", false);
    }

    function drop(event: any) {
      names.value = [];
      files.value = [];
      if (event) {
        for (let i = 0; i < event.target.files.length; i++) {
          files.value.push(event.target.files[i]);
          names.value.push(event.target.files[i].name);
        }
      }
    }

    async function uploadFiles() {
      loading.value = true;
      const data = await submitDocuments(props.talentId, files.value);
      if (data) {
        closeModal();
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    return {
      uploadFiles,
      drop,
      names,
      closeModal,
    };
  },
});
