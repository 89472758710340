
import { defineComponent } from "vue";
import { stripeAddCard } from "@/components/api/billing.api";

export default defineComponent({
  name: "Modal Add Payment Method",
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:openModal"],
  setup() {
    async function addCard() {
      const url = await stripeAddCard();
      window.location.href = url;
    }

    return {
      addCard,
    };
  },
});
