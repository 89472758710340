import { MutationTree } from "vuex";
import { ReimbursementsState } from "./types";

export enum SessionMutations {
  SET_REFRESH = "SET_REFRESH",
}

export const mutations: MutationTree<ReimbursementsState> = {
  [SessionMutations.SET_REFRESH](state, payload: boolean) {
    state.refresh = payload;
  },
};
