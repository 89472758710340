
import { defineComponent, Ref, ref, onMounted, computed } from "vue";
import { getOnboardingList } from "@/components/api/onboarding.api";

export default defineComponent({
  name: "Onboarding",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const loading = ref(false);
    const error = ref(false);
    const list: Ref<Array<any>> = ref([]);

    async function getList() {
      loading.value = true;
      const user_id = props.id;
      const data = await getOnboardingList(user_id);
      if (data) {
        list.value = data;
      } else {
        error.value = true;
      }
      loading.value = false;
    }

    const completedTasks = computed(
      () => list.value.filter((x) => x.completed === true).length
    );

    onMounted(() => {
      getList();
    });

    return { loading, error, list, completedTasks };
  },
});
