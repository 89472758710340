
import { defineComponent } from "vue";

export default defineComponent({
  name: "Date Formatter",
  props: {
    date: String,
  },
  computed: {
    newDate() {
      const separator = this.date?.substr(2, 1);
      let monthText = "";
      let year = "";
      let month = "";
      let day = "";

      if (separator !== "/") {
        year = this.date?.substr(0, 4) as string;
        month = this.date?.substr(5, 2) as string;
        day = this.date?.substr(8, 2) as string;
      } else {
        year = this.date?.substr(6, 4) as string;
        month = this.date?.substr(3, 2) as string;
        day = this.date?.substr(0, 2) as string;
      }

      switch (month) {
        case "01":
          monthText = "Jan";
          break;
        case "02":
          monthText = "Feb";
          break;
        case "03":
          monthText = "Mar";
          break;
        case "04":
          monthText = "Apr";
          break;
        case "05":
          monthText = "May";
          break;
        case "06":
          monthText = "Jun";
          break;
        case "07":
          monthText = "Jul";
          break;
        case "08":
          monthText = "Aug";
          break;
        case "09":
          monthText = "Sep";
          break;
        case "10":
          monthText = "Oct";
          break;
        case "11":
          monthText = "Nov";
          break;
        case "12":
          monthText = "Dec";
          break;
      }

      return monthText + " " + day + ", " + year;
    },
  },
});
