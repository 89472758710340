import { Addon } from "@/types/addon.interface";
import { EmergencyContact } from "@/types/emergencyContact.interface";

import { client } from "./baseclient";

export async function getTalents() {
  try {
    const { data } = await client.get("/talents");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getTalent(id: string) {
  try {
    const { data } = await client.get(`/talents/${id}`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function modifyTalentJob(
  id: string,
  title: string,
  description: string,
  responsibilities: string,
  requirements: string,
  benefits: string
) {
  const body = {
    title,
    description,
    responsibilities,
    requirements,
    benefits,
  };
  try {
    const { data } = await client.put(`/talents/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function modifyTalentInformation(
  id: string,
  title: string,
  curp: string,
  rfc: string,
  date_of_entry: string,
  date_of_entry_imss: string,
  bank: string,
  account: string
) {
  const body = {
    title,
    CURP: curp,
    RFC: rfc,
    FechaIngreso: date_of_entry,
    FechaIMSS: date_of_entry_imss,
    Banco_ID: bank,
    Clabe: account,
  };
  try {
    const { data } = await client.put(`/talents/${id}`, body);
    return data;
  } catch (err) {
    return null;
  }
}

export async function addAddon(id: string, addon: Addon) {
  try {
    const { data } = await client.post(`/users/${id}/addons`, addon);
    return data;
  } catch (err) {
    return null;
  }
}

export async function modifyAddon(id: string, addon: Addon) {
  try {
    const { data } = await client.put(`/users/${id}/addons`, addon);
    return data;
  } catch (err) {
    return null;
  }
}

export async function createNomilineaTalent() {
  try {
    const { data } = await client.post("/talents");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getNomilineaOptions() {
  try {
    const { data } = await client.get("/nomilinea_options");
    return data;
  } catch (err) {
    return null;
  }
}

export async function getEmergencyContacts(talent_id: string) {
  try {
    const { data } = await client.get(
      `/talents/${talent_id}/emergency_contacts`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function addEmergencyContact(
  talent_id: string,
  first_name: string,
  last_name: string,
  phone: string,
  relationship: string,
  address: string
) {
  const body = {
    emergency_contact: {
      first_name,
      last_name,
      relationship,
      phone,
      address,
    },
  };
  try {
    const { data } = await client.post(
      `/talents/${talent_id}/emergency_contacts`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function editEmergencyContact(
  talent_id: string,
  contact: EmergencyContact
) {
  const body = {
    emergency_contact: {
      ...contact,
    },
  };
  const contact_id = contact.id;
  try {
    const { data } = await client.put(
      `/talents/${talent_id}/emergency_contacts/${contact_id}`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function deleteEmergencyContact(
  talent_id: string,
  contact_id: string
) {
  try {
    const { data } = await client.delete(
      `talents/${talent_id}/emergency_contacts/${contact_id}`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function makePrimaryEmergenyContact(
  talent_id: string,
  contact_id: string
) {
  const body = {
    emergency_contact: {
      primary: true,
    },
  };
  try {
    const { data } = await client.put(
      `/talents/${talent_id}/emergency_contacts/${contact_id}`,
      body
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function getDocuments(user_id: string) {
  try {
    const { data } = await client.get(`/users/${user_id}/documents`);
    return data;
  } catch (err) {
    return null;
  }
}

export async function submitDocuments(user_id: string, files: any) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("documents[]", files[i]);
  }
  try {
    const { data } = await client.post(`/users/${user_id}/documents`, formData);
    return data;
  } catch (err) {
    return null;
  }
}

export async function deleteDocument(user_id: string, index: number) {
  try {
    const { data } = await client.delete(
      `/users/${user_id}/documents/${index}`
    );
    return data;
  } catch (err) {
    return null;
  }
}
