
import { defineComponent } from "vue";
import { addAccountMembers } from "@/components/api/accountMembers.api";

export default defineComponent({
  name: "Invite Members Modal",
  emits: ["update:openModal"],
  props: {
    openModal: Boolean,
  },
  data: () => ({
    invitedEmails: [] as Array<string>,
    options: ["Admin"],
    newEmail: "",
    emailError: false,
    apiError: false,
    loading: false,
  }),
  methods: {
    addToInvites(email: string) {
      const validate = email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (validate) {
        this.invitedEmails.push(email);
      } else {
        this.emailError = true;
      }
      this.newEmail = "";
    },
    removeInvite(index: number) {
      this.invitedEmails.splice(index, 1);
    },
    async sendInvitation() {
      this.apiError = false;
      this.loading = true;
      const data = await addAccountMembers(this.invitedEmails);
      if (data) {
        this.loading = false;
        this.invitedEmails = [];
      } else {
        this.loading = false;
        this.apiError = true;
      }
    },
    changeModalStatus() {
      this.$emit("update:openModal", false);
    },
  },
});
