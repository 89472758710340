
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import UserCard from "@/components/UserCard.vue";
import Spinner from "@/components/Spinner.vue";
import ErrorState from "@/components/ErrorState.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import Timeline from "@/components/Timeline.vue";
import { getDashboard } from "@/components/api/dashboard.api";
import { Dashboard } from "@/types/dashboard.interface";

export default defineComponent({
  name: "Home",
  components: {
    Navbar,
    UserCard,
    DateFormatter,
    Spinner,
    ErrorState,
    Timeline,
  },
  data: () => ({
    dashboard: {} as Dashboard,
    componentKey: 0,
    timelineParams: {
      type: "sourceable_type",
      value: "Reimbursement",
    },
    refreshTimeline: false,
    timelineOptions: [
      /*{
        name: "All",
        isActive: true,
      },*/
      {
        name: "Reimbursements",
        isActive: true,
      },
      {
        name: "Payrolls",
        isActive: false,
      },
    ],
    loading: false,
    error: false,
  }),
  computed: {
    userFirstName() {
      return this.$store.getters.getUserFirstName;
    },
    userLastName() {
      return this.$store.getters.getUserLastName;
    },
    currency() {
      return this.$store.getters.getCurrency;
    },
    greeting() {
      const date = new Date();
      const hours = date.getHours();
      let message = "";
      if (hours >= 5 && hours < 12) {
        message = "morning";
      } else if (hours >= 12 && hours < 18) {
        message = "afternoon";
      } else {
        message = "evening";
      }
      return message;
    },
    showSuggestions() {
      return this.$store.getters.getShowSuggestedActions;
    },
  },
  async mounted() {
    this.loading = true;
    const data = await getDashboard();
    if (data) {
      this.dashboard = data;
    } else {
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    stopShowingSuggestions() {
      this.$store.dispatch("stopShowingSuggestions");
    },
    changeTimelineParams(type: number) {
      for (let i = 0; i < this.timelineOptions.length; i++) {
        if (i === type) {
          this.timelineOptions[i].isActive = true;
        } else {
          this.timelineOptions[i].isActive = false;
        }
      }
      if (type === 0) {
        this.timelineParams.type = "sourceable_type";
        this.timelineParams.value = "Reimbursement";
      } else if (type === 1) {
        this.timelineParams.type = "sourceable_type";
        this.timelineParams.value = "PayrollPeriod";
      } else {
        this.timelineParams.type = "";
        this.timelineParams.value = "";
      }
      this.componentKey += 1;
    },
  },
});
