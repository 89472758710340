
import { defineComponent, PropType } from "vue";
import { Reimbursement } from "@/types/reimbursement.interface";
import UserCard from "@/components/UserCard.vue";

export default defineComponent({
  name: "Modal Reason",
  components: {
    UserCard,
  },
  props: {
    openReason: Boolean,
    reimbursement: Object as PropType<Reimbursement>,
  },
  emits: ["update:openReason"],
  methods: {
    closeModal() {
      this.$emit("update:openReason", false);
    },
  },
});
