import { Module } from "vuex";

import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getter";
import { mutations } from "./mutations";
import { UserState } from "./types";

const state: UserState = {
  email: "",
  first_name: "",
  last_name: "",
  user_id: "",
  role: "",
  company_id: "",
  company_name: "",
  show_suggested_actions: true,
  currency: "",
  company_products: [],
  payments: [],
};

export const user: Module<UserState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
