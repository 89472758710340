
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import AccountMembers from "@/components/Settings/AccountMembers.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import Billing from "@/components/Settings/Billing.vue";

export default defineComponent({
  name: "Settings",
  components: {
    Navbar,
    AccountMembers,
    AccountSettings,
    Billing,
  },
  data: () => ({
    selectedPage: "",
    menu: [
      {
        name: "account-settings",
        icon: "settings",
        selected: false,
      },
      {
        name: "account-members",
        icon: "members",
        selected: false,
      },
      {
        name: "billing",
        icon: "dollar",
        selected: false,
      },
      /*{
        name: "upgrade",
        icon: "save",
        selected: false,
      }*/
    ],
  }),
  methods: {
    changePage(index: number) {
      this.selectedPage = this.menu[index].name;
      /*for (let i = 0; i < this.menu.length; i++) {
        if (i === index) {
          this.menu[i].selected = true;
        } else {
          this.menu[i].selected = false;
        }
      }*/
      this.$router.push(`/settings/${this.selectedPage}`);
    },
    refreshPage() {
      const link = this.$route.params.subpage;
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].name === link) {
          this.selectedPage = link;
          this.menu[i].selected = true;
        } else {
          this.menu[i].selected = false;
        }
      }
    },
  },
  mounted() {
    this.refreshPage();
  },
  updated() {
    this.refreshPage();
  },
});
