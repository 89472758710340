import store from "@/store";
import { AxiosError } from "axios";

import { client } from "./baseclient";

export async function getReimbursements() {
  const company_id = store.getters.getCompanyId;
  try {
    const { data } = await client.get(
      `/companies/${company_id}/reimbursements`
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function editReimbursement(reimbursment_id: string, body: any) {
  const formData = new FormData();
  const amountCents = String(body.amount * 100);
  formData.append("reimbursement[title]", body.title);
  formData.append("reimbursement[amount]", amountCents);
  formData.append("reimbursement[currency]", body.currency);
  formData.append("reimbursement[detail]", body.detail);
  formData.append("reimbursement[note]", body.note);
  for (let i = 0; i < body.vouchers.length; i++) {
    formData.append("reimbursement[vouchers][]", body.vouchers[i]);
  }
  try {
    const { data } = await client.put(
      `/reimbursements/${reimbursment_id}`,
      formData
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function updateReimbursement(
  note: string,
  status: string,
  id: string
) {
  const body = {
    note: note,
    status: status,
  };
  try {
    const { data } = await client.put(`/reimbursements/${id}`, body);
    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      return error.response.data;
    }
  }
}

export async function createReimbursement(user_id: string, body: any) {
  const formData = new FormData();
  const amountCents = String(body.amount * 100);
  formData.append("reimbursement[title]", body.title);
  formData.append("reimbursement[amount]", amountCents);
  formData.append("reimbursement[currency]", body.currency);
  formData.append("reimbursement[detail]", body.detail);
  formData.append("reimbursement[note]", body.note);
  for (let i = 0; i < body.vouchers.length; i++) {
    formData.append("reimbursement[vouchers][]", body.vouchers[i]);
  }
  try {
    const { data } = await client.post(
      `/users/${user_id}/reimbursements`,
      formData
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    return error.response?.data;
  }
}
